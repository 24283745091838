import { useLocation, useNavigate } from "react-router-dom";

import AdminWrapper from "../home/AdminWrapper";
import Advanced from "./Advanced";
import Authentication from "./Authentication";
import Branding from "./Branding";
import ContentLibrary from "./ContentLibrary";
import CustomBlocks from "./CustomBlocks";
import CustomVariables from "./CustomVariables";
import Domains from "./Domains";
import General from "./General";
import Integrations from "./Integrations";
import Navigation from "./Navigation";
import Password from "./Password";
import Security from "./Security";
import { SettingsWrapper } from "./components/SettingsWrapper";
import Styling from "./Styling";
import Translations from "./Translations";
import { getUrlParameter } from "app/utils/utils";

const Settings = () => {
  const location = useLocation();
  const tab = getUrlParameter("tab", location) || "general";
  const navigate = useNavigate();

  let sections = [
    {
      id: "general",
      label: "General",
      component: General,
      icon: "FiSettings",
    },
    {
      id: "branding",
      label: "Branding",
      component: Branding,
      icon: "FiTag",
    },
    {
      id: "styling",
      label: "Styling",
      component: Styling,
      icon: "FiEdit",
    },
    {
      id: "navigation",
      label: "Navigation",
      component: Navigation,
      icon: "FiCompass",
    },
    {
      id: "security",
      label: "Security",
      component: Security,
      icon: "FiLock",
    },
    {
      id: "contentLibrary",
      label: "Content Library",
      component: ContentLibrary,
      icon: "FiBook",
    },
    {
      id: "authentication",
      label: "Authentication",
      component: Authentication,
      icon: "FiLock",
    },
    {
      id: "customVariables",
      label: "Custom Variables",
      component: CustomVariables,
      icon: "FiBox",
    },
    {
      id: "customBlocks",
      label: "Custom Blocks",
      component: CustomBlocks,
      icon: "FiTool",
    },
    {
      id: "advanced",
      label: "Advanced",
      component: Advanced,
      icon: "FiSliders",
    },
    {
      id: "domains",
      label: "Domains",
      component: Domains,
      icon: "FiGlobe",
    },
    {
      id: "translations",
      label: "Translations",
      component: Translations,
      icon: "FiType",
    },
    {
      id: "integrations",
      label: "Integrations",
      component: Integrations,
      icon: "FiGitPullRequest",
    },
    {
      id: "password",
      label: "Password",
      component: Password,
      icon: "FiKey",
    },
    // {
    //   id: "beta",
    //   label: "Beta Features",
    //   component: BetaFeatures,
    //   icon: "HiOutlineBeaker",
    // },
  ].map((s) => ({
    ...s,
    onClick: () => navigate(`/settings?tab=${s.id}`),
  }));

  const currentSection = sections.find((section) => section.id === tab);
  const Component = currentSection.component;

  return (
    <AdminWrapper contentWidth={"100%"} padding="0px">
      <SettingsWrapper sections={sections} tab={tab} label="Settings">
        <Component />
      </SettingsWrapper>
    </AdminWrapper>
  );
};

export default Settings;
