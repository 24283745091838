import {
  addFetchingBlocks,
  rApp,
  rFetchingBlockIds,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import {
  getCurrentDomain,
  getGoogleSheetsEndpoint,
  isFrontlyAdmin,
} from "app/utils/utils";
import { useEffect, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import { apiRequest } from "app/utils/apiRequests";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";

export const useAdminData = (page) => {
  const blocks = get(page, "blocks", []);

  const [savedDataSources, setSavedDataSources] =
    useRecoilState(rSavedSpreadsheets);

  const app = useRecoilValue(rApp);

  // Check if the data policy is set to "live"
  const dataPolicy = get(app, "data_policy", "live");

  const addToFetchingBlocks = useSetRecoilState(addFetchingBlocks);

  const resetFetchingBlockIds = useResetRecoilState(rFetchingBlockIds);

  const [isReady, setIsReady] = useState(false);

  const [fetchedSources, setFetchedSources] = useState([]);

  const dataSourceIds = [
    ...new Set(blocks.map((b) => b.spreadsheet).filter(Boolean)),
  ];

  // Create service map
  let serviceTypeMap = {};
  savedDataSources.forEach((s) => {
    serviceTypeMap[s.id] = get(s, "service", "google_sheets");
  });

  const unfetched = dataSourceIds.filter((id) => !fetchedSources.includes(id));

  const hasUnFetchedSheets = unfetched.length > 0;

  // Find all the sources that have already been loaded
  useEffect(() => {
    let alreadyLoaded = [];
    Object.keys(savedDataSources).forEach((key) => {
      const source = get(savedDataSources, key, null);
      const sourceData = get(source, "data", []);

      if (sourceData.length > 0) {
        alreadyLoaded.push(source.id);
      }

      setFetchedSources(alreadyLoaded);
      setIsReady(true);
    });
  }, []);

  useEffect(() => {
    if (
      isReady &&
      dataPolicy === "live" &&
      isFrontlyAdmin &&
      unfetched.length > 0
    ) {
      fetchSpreadsheets();
    }
  }, [hasUnFetchedSheets, isReady]);

  // Fetch spreadsheets from API based on blocks
  const fetchSpreadsheets = () => {
    setFetchedSources([...fetchedSources, ...unfetched]);

    let matchingBlockIds = [];

    blocks.forEach((block) => {
      if (unfetched.includes(block.spreadsheet)) {
        matchingBlockIds.push(block.id);
      }
    });

    addToFetchingBlocks(matchingBlockIds);

    const sheetRequests = unfetched.map((sheetId) => ({
      id: sheetId,
      block_id: sheetId, // Pretending that the block ID is the sheet ID
      service: get(serviceTypeMap, sheetId),
    }));

    apiRequest
      .post(getGoogleSheetsEndpoint(), {
        endpoint_type: "multi",
        spreadsheets: sheetRequests,
        domain: getCurrentDomain(),
      })
      .then((response) => {
        const error = get(response, ["data", "error"]);

        if (error) {
          errorNotification(error);
        }

        const newData = get(response, ["data"], []);

        setSavedDataSources(
          savedDataSources.map((s) => {
            const matchingData = get(newData, s.id, null);
            if (matchingData) {
              return {
                ...s,
                data: matchingData,
              };
            }
            return s;
          })
        );

        resetFetchingBlockIds();
      });
  };
};
