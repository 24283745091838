import PageStyles, { PanelHeader } from "./configPanel/PageStyles";
import { boxShadow, colors } from "app/utils/theme";
import { createRef, useRef } from "react";
import { getDetailViewMode, getPixels, truncateText } from "app/utils/utils";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rDarkMode,
  rScreenPreviewSize,
  rShowSettingsPanel,
  rShowStylesPanel,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import ActiveBlockConfig from "./configPanel/ActiveBlockConfig";
import ActiveBlockStyles from "./configPanel/ActiveBlockStyles";
import { Breadcrumb } from "app/components";
import CustomBlockConfig from "./configPanel/CustomBlockConfig";
import CustomBlockStyles from "./configPanel/CustomBlockStyles";
import DetailViewSettings from "./configPanel/DetailViewSettings";
import DetailViewStyles from "./configPanel/DetailViewStyles";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Header from "./Header";
import PageConfig from "./configPanel/PageConfig";
import SetupMode from "app/renderPage/SetupMode";
import TooltipIcon from "./configPanel/components/TooltipIcon";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import { useAdminData } from "../useAdminData";
import useContainerDimensions from "app/utils/useContainerDimensions";
import useDeleteBlock from "./useDeleteBlock";
import { useEffect } from "react";
import useIsCustomBlock from "app/utils/useIsCustomBlock";
import usePage from "app/utils/usePage";

const PageBuilder = () => {
  const darkMode = useRecoilValue(rDarkMode);

  const isCustomBlock = useIsCustomBlock();

  const page = usePage();
  const activeBlock = useActiveBlock();
  const blocks = get(page, "blocks", []);

  useAdminData(page);

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const activeApp = useRecoilValue(rApp);

  const [showStylesPanel, setShowStylesPanel] =
    useRecoilState(rShowStylesPanel);

  const [showSettingsPanel, setShowSettingsPanel] =
    useRecoilState(rShowSettingsPanel);

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const setActiveDetailViewId = useSetRecoilState(rActiveDetailViewId);
  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const defaultPageBackgroundColor = get(activeApp, [
    "styling",
    "pageBackgroundColor",
  ]);

  const screenPreviewSize = useRecoilValue(rScreenPreviewSize);

  const activeDetailBlock = blocks.find((b) => b.id === activeDetailViewId);

  const ref = createRef(null);

  useEffect(() => {
    mixpanel.track("Page Builder View");
  }, []);

  // Handle the detection of the 'delete' key being pressed in the admin and deleting the active block
  const { deleteBlock } = useDeleteBlock();

  const previewRef = useRef();
  const { width } = useContainerDimensions(previewRef, screenPreviewSize);

  let sidebarWidth = 0;
  if (showStylesPanel) {
    sidebarWidth += 280;
  }
  if (showSettingsPanel) {
    sidebarWidth += 290;
  }

  const contentWidth = get(page, "contentWidth") || "100%";

  let previewWidth =
    contentWidth === "100%" ? width - sidebarWidth : contentWidth;

  if (screenPreviewSize === "tablet") {
    previewWidth = 800;
  }

  if (screenPreviewSize === "mobile") {
    previewWidth = 400;
  }

  const headerHeight = activeDetailViewId ? 115 : 60;

  const getBreadcrumbs = () => {
    if (activeBlock) {
      let initialCrumbText = null;

      if (activeDetailViewId) {
        initialCrumbText = "Detail View";
      } else if (isCustomBlock) {
        initialCrumbText = "Custom Block";
      } else {
        initialCrumbText = "Page";
      }

      return [
        {
          text: initialCrumbText,
          onClick: () => {
            setActiveBlockId(null);
            setActiveEditField(null);
          },
        },
        {
          text: truncateText(
            get(activeBlock, "label") || get(activeBlock, "componentId"),
            16
          ),
        },
      ];
    } else {
      return [
        {
          text: isCustomBlock
            ? "Edit Custom Block"
            : activeDetailViewId
            ? "Detail View Settings"
            : "Page Settings",
          onClick: null,
        },
      ];
    }
  };

  const breadcrumbs = getBreadcrumbs();

  const detailViewMode = getDetailViewMode(activeDetailBlock, blocks);

  const modalPreview = blocks.length === 0 || detailViewMode;

  let detailViewCrumbs = [];

  if (activeDetailViewId) {
    const parentBlock = blocks.find(
      (b) => b.id === get(activeDetailBlock, "parent")
    );

    // TODO - This works for 2 layers deep, but then dies
    detailViewCrumbs = parentBlock
      ? [
          {
            text: get(parentBlock, "label") || get(parentBlock, "componentId"),
            onClick: () => {
              setActiveDetailViewId(null);
              setActiveBlockId(get(parentBlock, "id"));
              setActiveEditField(null);
            },
          },
          {
            text: `${
              get(activeDetailBlock, "label") ||
              get(activeDetailBlock, "componentId")
            } Detail View`,
            onClick: () => {
              setActiveDetailViewId(get(parentBlock, "id"));
              setActiveBlockId(null);
              setActiveEditField(null);
            },
          },
          {
            text: "Detail View",
          },
        ]
      : [
          {
            text:
              get(activeDetailBlock, "label") ||
              get(activeDetailBlock, "componentId"),
            onClick: () => {
              setActiveDetailViewId(null);
              setActiveBlockId(null);
              setActiveEditField(null);
            },
          },
          {
            text: "Detail View",
          },
        ];
  }

  return (
    <Container>
      <DndProvider backend={HTML5Backend}>
        <Header />
        {activeDetailViewId && (
          <DetailViewBanner>
            <Breadcrumb items={detailViewCrumbs} fontSize={18} whiteText />
          </DetailViewBanner>
        )}
        <Body ref={previewRef} headerHeight={headerHeight}>
          <ConfigContainer
            className="open-settings-button"
            expanded={showSettingsPanel}
            onClick={
              !showSettingsPanel ? () => setShowSettingsPanel(true) : null
            }
          >
            <PanelContent expanded={showSettingsPanel}>
              <PanelHeader
                label={
                  activeBlock
                    ? "Block Settings"
                    : activeDetailViewId
                    ? "Detail View Settings"
                    : "Page Settings"
                }
                expanded={showSettingsPanel}
                setExpanded={setShowSettingsPanel}
                direction="left"
                breadcrumbs={breadcrumbs}
                deleteBlock={
                  get(activeApp, "show_active_block_delete_button") &&
                  activeBlock
                    ? deleteBlock
                    : null
                }
              />
              {isCustomBlock && !activeBlock && !activeDetailViewId && (
                <CustomBlockConfig />
              )}
              {!isCustomBlock && !activeBlock && !activeDetailViewId && (
                <PageConfig />
              )}
              {activeDetailViewId && !activeBlock && <DetailViewSettings />}
              {activeBlock && <ActiveBlockConfig deleteBlock={deleteBlock} />}
            </PanelContent>
            {!showSettingsPanel && (
              <TooltipIcon
                anchorTag=".open-settings-button"
                expanded={showSettingsPanel}
                tooltip="Show Settings"
                icon="BsFillGearFill"
              />
            )}
          </ConfigContainer>

          <PreviewContent
            width={previewWidth}
            ref={ref}
            modalPreview={modalPreview}
            backgroundColor={
              darkMode
                ? colors.darkModeBackground
                : get(page, "backgroundColor") || defaultPageBackgroundColor
            }
          >
            <SetupMode
              page={page}
              availableWidth={previewWidth}
              screenPreviewSize={screenPreviewSize}
            />
          </PreviewContent>

          <StylesContainer
            className="open-styles-button"
            expanded={showStylesPanel}
            onClick={!showStylesPanel ? () => setShowStylesPanel(true) : null}
          >
            <PanelContent expanded={showStylesPanel}>
              {activeBlock && <ActiveBlockStyles />}
              {isCustomBlock && !activeBlock && !activeDetailViewId && (
                <CustomBlockStyles />
              )}
              {!isCustomBlock && !activeBlock && !activeDetailViewId && (
                <PageStyles />
              )}
              {!isCustomBlock && !activeBlock && activeDetailViewId && (
                <DetailViewStyles />
              )}
            </PanelContent>
            {!showStylesPanel && (
              <TooltipIcon
                anchorTag=".open-styles-button"
                expanded={showStylesPanel}
                tooltip="Show Style Settings"
                icon="FaPaintBrush"
              />
            )}
          </StylesContainer>
        </Body>
      </DndProvider>
    </Container>
  );
};

export default PageBuilder;

const ConfigContainer = styled.div`
  min-width: ${(p) => (p.expanded ? "290px" : "46px")};
  max-width: ${(p) => (p.expanded ? "290px" : "46px")};
  overflow-y: auto;
  border-right: 1px solid #e0e1e6;
  background: white;
  height: calc(100% - 0px);
  position: relative;
  transition: 0.3s;
  ${(p) =>
    !p.expanded &&
    `
    height: 46px;
    width: 46px;
    border-radius: 46px;
    border: 1px solid #e0e1e6;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 65px;
    left: 5px;
    cursor: pointer;
    z-index: 9999;
    background: white;
    &:hover{
      box-shadow: ${boxShadow.card};
    }
  `};
`;

const StylesContainer = styled.div`
  min-width: ${(p) => (p.expanded ? "280px" : "46px")};
  max-width: ${(p) => (p.expanded ? "280px" : "46px")};
  border-left: 1px solid #e0e1e6;
  background: white;
  height: calc(100% - 0px);
  position: relative;
  transition: 0.3s;
  ${(p) =>
    !p.expanded &&
    `
    height: 46px;
    width: 46px;
    border-radius: 46px;
    border: 1px solid #e0e1e6;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 65px;
    right: 5px;
    cursor: pointer;
    z-index: 9999;
    background: white;
    &:hover{
      box-shadow: ${boxShadow.card};
    }
  `};
`;

const PanelContent = styled.div`
  opacity: ${(p) => (p.expanded ? 1 : 0)};
  overflow-y: auto;
  height: calc(100% - 0px);
  transition: 0.3s;
`;

const DetailViewBanner = styled.div`
  background: white;
  padding: 15px 20px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  height: 55px;
  background: ${colors.grey4};
`;

const Container = styled.div`
  padding: 0px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const Body = styled.div`
  display: flex;
  height: calc(100% - ${(p) => getPixels(p.headerHeight)});
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewContent = styled.div`
  width: ${(p) => getPixels(p.width)};
  overflow-y: auto;
  height: 100%;
  background: ${(p) => p.backgroundColor || colors.clickUpBackground};
  margin: 0 auto;
  ${(p) =>
    p.modalPreview &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;
